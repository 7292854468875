<template>
  <div>
    <div class="title flexB" v-if="isTitle">
      <h1>{{ $t("user-info-edit") }}</h1>
<!--      <div>
        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
      </div>-->
    </div>
    <div class="contents">
      <div class="mainBox edit">
        <div class="flexL">
          <p class="bold">{{ $t("user-data-num") }}</p>
          <p>{{ userId }}</p>
        </div>
        <div class="flexL">
          <p class="bold">{{ $t("user-data-regdate") }}</p>
          <p>{{ moment(registerDate).format("YYYY.MM.DD HH:mm:ss") }}</p>
        </div>
        <div class="flexL">
          <p class="bold">
            {{ $t("user-data-email-id") }}
            <span>*</span>
          </p>
          <p>{{ loginId }}</p>
        </div>
        <div class="flexL">
          <p class="bold">{{ $t("user-data-last-login-date") }}</p>
          <p>{{ moment(loginDate).format("YYYY.MM.DD HH:mm:ss") }}</p>
        </div>
        <div class="flexL">
          <p class="bold">
            {{ $t("user-data-name") }}
            <span>*</span>
          </p>
          <p>{{ name }}</p>
        </div>
        <div class="flexL">
          <p class="bold">
            {{ $t("user-data-phone-num") }}
            <span>*</span>
          </p>
          <input
              type="text"
              :value="phone"
              @input="bindNumber"
          />
        </div>
        <div class="flexL">
          <p class="bold">{{ $t("user-data-dept-name") }}</p>
          <select v-model="departmentId">
            <option disabled value>{{ $t("user-data-dept-select") }}</option>
            <option
                v-for="(data, i) in departmentList"
                :key="i"
                :value="data.departmentId"
            >
              {{ data.name }}
            </option>
          </select>
        </div>
        <div class="flexL">
          <p class="bold">{{ $t("user-data-work") }}</p>
          <input type="text" v-model="ownerWork" />
        </div>
        <div class="flexL">
          <p class="bold">
            {{ $t("user-data-authority") }}
            <span>*</span>
          </p>
          <p>
            {{
              userType == "Admin"
                  ? $t("user-data-type-admin")
                      : userType == "User"
                          ? $t("user-data-type-user")
                          : "-"
            }}
          </p>
        </div>
        <div class="flexL password">
          <p class="bold">
            {{ $t("user-data-pw-change") }}
            <span>*</span>
          </p>
          <div>
            <input type="password" v-model="password" />
            <span>
              {{ $t("user-data-pw-input-instruction") }}
            </span>
          </div>
        </div>
        <div class="flexL password">
          <p class="bold">
            {{ $t("user-data-pw-confirm") }}
            <span>*</span>
          </p>
          <div>
            <input type="password" v-model="passwordConfirm" />

            <span
                v-if="passwordConfirm && password != passwordConfirm"
                class="errror"
            >{{ $t("user-data-pw-input-correct-msg") }}</span
            >
          </div>
        </div>
        <div class="buttonWrap">
          <button class="point large" @click="submit">{{ $t("btn-data-change") }}</button>
          <button class="large" @click="$router.go(-1)">{{ $t("btn-cancel") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import sha256 from "js-sha256";
import { format } from "@/mixins/format";
import moment from "moment";
import {
  fetchDepartmentList,
} from "@/api/department";
import {
  fetchUserDetails,
  updateUser,
} from "@/api/user";
export default {
  mixins: [format],
  data() {
    return {
      moment: moment,
      status: "",
      userType: "",
      registerDate: "",
      ownerWork: "",
      phone: "",
      name: "",
      accountId: "",
      loginId: "",
      password: "",
      passwordConfirm: "",
      departmentList: [],
      error: false,
      departmentId: "",
      loginDate: "",
      userId: "",
      isTitle:true,
    };
  },
  created() {
    if(this.$route.name == "userEdit2"){
      this.isTitle = false;
      this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 13 });
    }
  },
  mounted() {
    this.getDepartmentList();
    this.getUserDetails();
  },
  watch : {
    phone(val){
      let check = /^[0-9]+$/;
      if (!check.test(val)) {
        this.phone = val.replace(/[^0-9]/g,'');
      }
    },
  },
  methods: {
    bindNumber(event){
      this.phone = event.target.value;
    },
    getDepartmentList() {
      var params = {
        statuses : "Used"
      }
      fetchDepartmentList(params).then((res) => {
        this.departmentList = res.data.data.content;
      });
    },
    getUserDetails() {
      let userId = localStorage.getItem("userId");
      // let token = localStorage.getItem("token");
      fetchUserDetails(userId).then((res) => {
        if (res.data.result == 0) {
          this.departmentId = res.data.data.departmentId;
          this.userId = res.data.data.userId;
          this.loginId = res.data.data.loginId;
          this.name = res.data.data.name;
          this.phone = res.data.data.phone;
          this.ownerWork = res.data.data.ownerWork;
          this.registerDate = res.data.data.registerDate;
          this.userType = res.data.data.userType;
          this.status = res.data.data.status;
          this.loginDate = res.data.data.loginDate;
        } else {
          alert(res.message);
        }
      });
    },
    submit() {
      if (this.name == "") {
        return alert(this.$t("alert-message-check-name"));
      } else if (this.phone == "") {
        return alert(this.$t("alert-message-check-phone-num"));
      }

      let data = {
        loginId: this.loginId,
        name: this.name,
        phone: this.phone,
        departmentId : this.departmentId,
        ownerWork: this.ownerWork,
        status: this.status,
        userType: this.userType,
      };

      if(this.password.trim() != ""){
        if (this.password != this.passwordConfirm) {
          return alert(this.$t("alert-message-check-pw-not-correct"));
        }

        if (!this.checkPasswordFormat(this.password)) {
          return alert(this.$t("alert-message-check-pw-format"));
        }

        data.password = this.password
      }

      let userId = localStorage.getItem("userId");
      updateUser(userId, data).then((res) => {
        if (res.data.result == 0) {
          alert(this.$t("top-mypage-pop-confirm-success"));
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    handleRefresh() {
      this.$router.go();
    },
  },
};
</script>
